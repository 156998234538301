import React from "react"
import Helmet from "react-helmet"

const Facebook = ({
  url,
  title,
  desc,
  siteName,
  imageFacebook,
  imageFacebookWidth,
  imageFacebookHeight,
  locale,
}) => {
  return (
    <Helmet>
      <meta property="og:type" content="website" />
      {url && <meta property="og:url" content={url} />}
      {title && <meta property="og:title" content={title} />}
      {desc && <meta property="og:description" content={desc} />}
      {siteName && <meta property="og:site_name" content={siteName} />}
      {locale && <meta property="og:locale" content={locale} />}
      {imageFacebook && <meta property="og:image" content={imageFacebook} />}
      {imageFacebookWidth && (
        <meta property="og:image:width" content={imageFacebookWidth} />
      )}
      {imageFacebookHeight && (
        <meta property="og:image:height" content={imageFacebookHeight} />
      )}
    </Helmet>
  )
}

export default Facebook
