import React from "react"

export const H1 = ({ children, display, className }) => {
  return (
    <h1
      className={`font-extrabold leading-relaxed ${
        display
          ? "text-3xl sm:text-5xl md:text-7xl font-display"
          : "text-2xl sm:text-3xl md:text-4xl"
      } ${className}`}
    >
      {children}
    </h1>
  )
}

export const H2 = ({ children, display, className }) => {
  return (
    <h2
      className={`font-extrabold leading-relaxed ${
        display
          ? "text-3xl sm:text-4xl md:text-5xl font-display"
          : "text-xl sm:text-2xl md:text-3xl"
      } ${className}`}
    >
      {children}
    </h2>
  )
}

export const H3 = ({ children, display, className }) => {
  return (
    <h3
      className={`font-extrabold leading-relaxed ${
        display
          ? "text-2xl sm:text-3xl md:text-4xl font-display"
          : "text-lg sm:text-xl md:text-2xl"
      } ${className}`}
    >
      {children}
    </h3>
  )
}

export const H4 = ({ children, className }) => {
  return (
    <h4
      className={`text-base font-extrabold leading-relaxed sm:text-lg md:text-xl ${className}`}
    >
      {children}
    </h4>
  )
}
