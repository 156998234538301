/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react"
import { Link } from "gatsby"
import { Popover, Transition } from "@headlessui/react"
import {
  MenuIcon,
  XIcon,
  PhotographIcon,
  BookOpenIcon,
  UsersIcon,
  MailIcon,
} from "@heroicons/react/outline"
import logo from "./../../media/logo_adler.svg"

const mainMenu = [
  {
    name: "Fotoreisen & Fotoexkursionen",
    href: "/fotoreisen-fotoexkursionen/",
    icon: PhotographIcon,
  },
  {
    name: "Verlag",
    href: "/verlag/",
    icon: BookOpenIcon,
  },
  {
    name: "Über uns",
    href: "/ueber-uns/",
    icon: UsersIcon,
  },
  {
    name: "Kontakt",
    href: "/kontakt/",
    icon: MailIcon,
  },
]
// const solutions = [
//   {
//     name: "Analytics",
//     description:
//       "Get a better understanding of where your traffic is coming from.",
//     href: "#",
//     icon: ChartBarIcon,
//   },
//   {
//     name: "Engagement",
//     description: "Speak directly to your customers in a more meaningful way.",
//     href: "#",
//     icon: CursorClickIcon,
//   },
//   {
//     name: "Security",
//     description: "Your customers' data will be safe and secure.",
//     href: "#",
//     icon: ShieldCheckIcon,
//   },
//   {
//     name: "Integrations",
//     description: "Connect with third-party tools that you're already using.",
//     href: "#",
//     icon: ViewGridIcon,
//   },
//   {
//     name: "Automations",
//     description:
//       "Build strategic funnels that will drive your customers to convert",
//     href: "#",
//     icon: RefreshIcon,
//   },
//   {
//     name: "Reports",
//     description:
//       "Get detailed reports that will help you make more informed decisions ",
//     href: "#",
//     icon: DocumentReportIcon,
//   },
// ]
// const resources = [
//   {
//     name: "Help Center",
//     description:
//       "Get all of your questions answered in our forums or contact support.",
//     href: "#",
//   },
//   {
//     name: "Guides",
//     description:
//       "Learn how to maximize our platform to get the most out of it.",
//     href: "#",
//   },
//   {
//     name: "Events",
//     description:
//       "See what meet-ups and other events we might be planning near you.",
//     href: "#",
//   },
//   {
//     name: "Security",
//     description: "Understand how we take your privacy seriously.",
//     href: "#",
//   },
// ]

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ")
// }

export default function Header({ current, menuFixed }) {
  return (
    <div className="relative" id="top">
      <header
        className={`bg-white ${menuFixed && "absolute top-0 z-30 w-full"}`}
      >
        <Popover className="relative">
          {({ open }) => (
            <>
              <div className="flex items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                  <Link to="/">
                    <span className="sr-only">Logo</span>
                    <div className="flex flex-row items-center gap-4">
                      {/* {adler} */}
                      <img className="w-auto h-8 mb-3" src={logo} alt="" />
                    </div>
                  </Link>
                </div>
                <div className="-my-2 -mr-2 md:hidden">
                  <Popover.Button className="inline-flex items-center justify-center p-2 text-black bg-white hover:text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand">
                    <span className="sr-only">Menü öffnen</span>
                    <MenuIcon className="w-6 h-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
                <div className="items-center justify-end hidden md:flex md:flex-1">
                  <Popover.Group as="nav" className="hidden space-x-4 md:flex">
                    {/* <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? "text-gray-900" : "text-black",
                          "group bg-white inline-flex items-center text-base hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                        )}
                      >
                        <span>Solutions</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "text-gray-600" : "text-gray-400",
                            "ml-2 h-5 w-5 group-hover:text-gray-900"
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className="absolute z-10 w-screen max-w-md mt-3 -ml-4 transform lg:max-w-2xl lg:ml-0 md:right-0"
                        >
                          <div className="overflow-hidden shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8 lg:grid-cols-2">
                              {solutions.map((item, i) => (
                                <Link
                                  key={i}
                                  to={item.href}
                                  className="flex items-start p-3 -m-3 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                                >
                                  <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white bg-brand sm:h-12 sm:w-12">
                                    <item.icon
                                      className="w-6 h-6"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div className="ml-4">
                                    <p className="text-base text-gray-900">
                                      {item.name}
                                    </p>
                                    <p className="mt-1 text-sm text-black">
                                      {item.description}
                                    </p>
                                  </div>
                                </Link>
                              ))}
                            </div>
                            <div className="p-5 bg-gray-50 sm:p-8">
                              <Link
                                to="/"
                                className="flow-root p-3 -m-3 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                              >
                                <div className="flex items-center">
                                  <div className="text-base text-gray-900">
                                    Enterprise
                                  </div>
                                  <span className="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs leading-5 bg-brand text-white">
                                    New
                                  </span>
                                </div>
                                <p className="mt-1 text-sm text-black">
                                  Empower your entire team with even more
                                  advanced tools.
                                </p>
                              </Link>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover> */}

                    {mainMenu.map((item, i) => (
                      <Link
                        key={i}
                        to={item.href}
                        className="p-2 text-base text-black transition-colors duration-300 hover:text-gray-900 focus:outline-none hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                      >
                        {item.name}
                      </Link>
                    ))}
                    {/* 

                <Link
                  to="/fotoreisen-fotoexkursionen/"
                 
                >
                  Fotoreisen
                </Link>

                <Link
                  to="/verlag/"
                  className="text-base text-black hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                >
                  Verlag
                </Link> */}
                    {/* 
                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? "text-gray-900" : "text-black",
                          "group bg-white inline-flex items-center text-base hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                        )}
                      >
                        <span>Fotoreisen</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "text-gray-600" : "text-gray-400",
                            "ml-2 h-5 w-5 group-hover:text-gray-900"
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className="absolute z-10 w-screen max-w-xs px-2 mt-3 transform md:right-0 sm:px-0"
                        >
                          <div className="overflow-hidden shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                              {resources.map((item, i) => (
                                <Link
                                  key={i}
                                  to={item.href}
                                  className="block p-3 -m-3 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                                >
                                  <p className="text-base text-gray-900">
                                    {item.name}
                                  </p>
                                  <p className="mt-1 text-sm text-black">
                                    {item.description}
                                  </p>
                                </Link>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? "text-gray-900" : "text-black",
                          "group bg-white inline-flex items-center text-base hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                        )}
                      >
                        <span>Verlag</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "text-gray-900" : "text-black",
                            "ml-2 h-5 w-5 group-hover:text-gray-900"
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className="absolute z-10 w-screen max-w-xs px-2 mt-3 transform md:right-0 sm:px-0"
                        >
                          <div className="overflow-hidden shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                              {resources.map((item, i) => (
                                <Link
                                  key={i}
                                  to={item.href}
                                  className="block p-3 -m-3 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand"
                                >
                                  <p className="text-base text-gray-900">
                                    {item.name}
                                  </p>
                                  <p className="mt-1 text-sm text-black">
                                    {item.description}
                                  </p>
                                </Link>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover> */}
                  </Popover.Group>
                </div>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  static
                  className="absolute inset-x-0 top-0 z-20 p-2 transition origin-top-right transform md:hidden"
                >
                  <div className="bg-white divide-y-2 shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50">
                    <div className="px-5 pt-5 pb-6">
                      <div className="flex items-center justify-between">
                        <div>
                          <img
                            className="w-auto h-8"
                            src={logo}
                            alt="Workflow"
                          />
                        </div>
                        <div className="-mr-2">
                          <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white hover:text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand">
                            <span className="sr-only">Menü schließen</span>
                            <XIcon className="w-6 h-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                      <div className="mt-6">
                        <nav className="grid grid-cols-1 gap-7">
                          {mainMenu.map((item, i) => (
                            <Link
                              key={i}
                              to={item.href}
                              className="flex items-center p-3 -m-3 hover:bg-gray-50"
                            >
                              <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white bg-brand">
                                <item.icon
                                  className="w-6 h-6"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-4 text-base text-gray-900">
                                {item.name}
                              </div>
                            </Link>
                          ))}
                        </nav>
                      </div>
                    </div>
                    {/* <div className="px-5 py-6">
                  <div className="grid grid-cols-2 gap-4">
                    <Link
                      to="/"
                      className="text-base text-gray-900 hover:text-gray-700"
                    >
                      Pricing
                    </Link>

                    <Link
                      to="/"
                      className="text-base text-gray-900 hover:text-gray-700"
                    >
                      Docs
                    </Link>

                    <Link
                      to="/"
                      className="text-base text-gray-900 hover:text-gray-700"
                    >
                      Enterprise
                    </Link>
                    {resources.map((item, i) => (
                      <Link
                        key={i}
                        to={item.href}
                        className="text-base text-gray-900 hover:text-gray-700"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className="mt-6">
                    <Link
                      to="/"
                      className="flex items-center justify-center w-full px-4 py-2 text-base text-white border border-transparent shadow-sm bg-brand hover:bg-brand-dark"
                    >
                      Sign up
                    </Link>
                    <p className="mt-6 text-base text-center text-black">
                      Existing customer?{" "}
                      <Link to="/" className="text-brand hover:text-brand-dark">
                        Sign in
                      </Link>
                    </p>
                  </div>
                </div> */}
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </header>
    </div>
  )
}
