import React from "react"
import Helmet from "react-helmet"

const AppleWebAppMeta = () => {
  return (
    <Helmet>
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
    </Helmet>
  )
}

export default AppleWebAppMeta
