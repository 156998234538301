import React from "react"

const Container = ({ children, noPadding, narrow, id, className }) => {
  return (
    <div
      className={`mx-auto px-4 ${
        narrow ? "max-w-screen-md" : "max-w-screen-xl"
      } ${noPadding ? "" : "my-8 md:my-16 lg:my-32"} ${
        className ? className : ""
      }`}
      id={id}
    >
      {children}
    </div>
  )
}

export default Container
