import React, { useState } from "react"
import { useEffect } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Favicons from "./meta/Favicons"
import AppleWebAppMeta from "./meta/AppleWebAppMeta"
import Facebook from "./meta/Facebook"
import Twitter from "./meta/Twitter"
import Header from "./Header"
import Footer from "./Footer"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { ArrowUpIcon } from "@heroicons/react/outline"
import { Transition } from "@headlessui/react"
// import "../modules/klaroCss.css"
// import { config } from "../modules/klaroConfig"
// let Klaro
// if (typeof window !== `undefined`) {
//   Klaro = require("klaro/dist/klaro-no-css")
// }

const Default = ({
  children,
  location,
  title = "Standard Title",
  desc = "Standard Desc",
  current,
  imageFacebook,
  imageFacebookWidth,
  imageFacebookHeight,
  imageTwitter,
  menuFixed,
}) => {
  let [scrollHeight, setScrollHeight] = useState(0)

  useEffect(() => {
    // if (
    //   typeof window !== `undefined` &&
    //   process.env.NODE_ENV !== "development"
    // ) {
    //   window.klaro = Klaro
    //   window.klaroConfig = config
    //   Klaro.setup(config)
    // }

    window.addEventListener("scroll", listenToScroll)
  })

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            author {
              name
              summary
            }
          }
        }
      }
    `
  )

  const listenToScroll = () => {
    const height = document.documentElement.scrollTop
    setScrollHeight(height)
  }

  return (
    <>
      <Helmet>
        <html lang="de" />
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta name="robots" content="index,follow" />
        <link rel="icon" href="/favicon.ico" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={data.site.siteMetadata.title} />
        <link
          rel="canonical"
          href={`${data.site.siteMetadata.siteUrl}${location.pathname}`}
        />
        <meta
          name="author"
          content={`${data.site.siteMetadata.author.name} - ${data.site.siteMetadata.author.summary}`}
        />
      </Helmet>

      {/* Favicon generte hier --> https://www.favicon-generator.org/   */}
      <Favicons />

      {/* AppleWebAppMeta */}
      <AppleWebAppMeta />

      {/* Facebook */}
      <Facebook
        url={data.site.siteMetadata.siteUrl + location.pathname}
        title={title}
        desc={desc && desc}
        siteName={title}
        locale="de_DE"
        imageFacebook={imageFacebook && imageFacebook}
        imageFacebookWidth={imageFacebookWidth && imageFacebookWidth}
        imageFacebookHeight={imageFacebookHeight && imageFacebookHeight}
      />

      {/* Twitter */}
      <Twitter
        url={data.site.siteMetadata.siteUrl + location.pathname}
        title={title}
        imageTwitter={imageTwitter && imageTwitter}
        desc={desc && desc}
      />

      {/* Scripts */}
      <Helmet>
        {/* EXMAPLE CHANGE SCRIPT Google Analytics 
        <script
          type="text/plain"
          data-type="application/javascript"
          data-name="googleAnalytics"
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-NQXRCWEYXD"
        ></script>
        <script
          type="text/plain"
          data-type="application/javascript"
          data-name="googleAnalytics"
          async
        >
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-NQXRCWEYXD'), { 'anonymize_ip': true };
          `}
        </script>

        */}
      </Helmet>

      <Header current={current} menuFixed={menuFixed} />
      {children}
      <Footer location={location} />
      <Transition
        show={scrollHeight > 1600}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <AnchorLink
          className="fixed z-20 inline-block p-2 transition duration-300 bg-white border border-black border-opacity-50 transistion-color hover:text-white hover:bg-black bg-opacity-30 right-2 bottom-2"
          to={`${location.pathname}#top`}
        >
          <ArrowUpIcon className="w-4 h-4" />
        </AnchorLink>
      </Transition>
    </>
  )
}

export default Default
